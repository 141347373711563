import type { UseSeoMetaInput } from '@unhead/schema';
import { generateHeadInfo } from '~/utils/seo';

export function useInsertSeoMeta(
    story: StoryblokStoryInterface,
    pageSpecificMeta?: MetaInfoPageSpecificBackupsInterface,
) {
    const route = useRoute();
    const config = useRuntimeConfig();
    const path = route.path === '/shop/community-solar-v2/' ? '/shop/community-solar/' : route.path;
    const metaInfo = generateMetaInfo(story, path, config.public.ES_DOMAIN, pageSpecificMeta);
    const headInfo = generateHeadInfo(story, path);

    useSeoMeta(metaInfo as unknown as UseSeoMetaInput);
    useHead(headInfo);
}

export function useInsertJsonLd(seoData?: SeoDataInterface, story?: StoryblokStoryInterface) {
    const route = useRoute();
    const config = useRuntimeConfig();

    let jsonLdInfo = {};
    if (!story && seoData) {
        jsonLdInfo = generateHomepageJsonLd({ seoData }, config.public.ES_DOMAIN);
    } else if (story && seoData && story?.content?.component !== 'AuthorPage') {
        jsonLdInfo = generateLandingPageJsonLd({ story: story, seoData, path: route.path }, config.public.ES_DOMAIN);
    } else if (story) {
        const storyContent = story.content as unknown as AuthorPageStoryInterface;
        jsonLdInfo = generateAuthorPageJsonLd({ storyContent, path: route.path }, config.public.ES_DOMAIN);
    }

    useHead({
        script: [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(jsonLdInfo),
            },
        ],
    });
}
