import StoryblokClient from 'storyblok-js-client';

let InnerStoryblok: StoryblokClient;
const Storyblok = () => {
    if (!InnerStoryblok) {
        InnerStoryblok = new StoryblokClient({
            accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
            region: 'us',
        });
    }
    return InnerStoryblok;
};

export interface Route {
    url: string;
    lastmod?: string | null;
    full_slug?: string;
    noIndex: boolean;
}

const TOKEN = process.env.STORYBLOK_ACCESS_TOKEN;
const PER_PAGE = 100;

export const determineIfStoryIsNoIndex = (story: StoryblokStoryInterface) => {
    if (story?.content?.component && story?.content?.component === 'LandingPage') {
        const landingPage = story.content as unknown as LandingPageBlokInterface;
        // @ts-expect-error todo LandingPageBlokInterface type needs to be updated in storyblok-shared or locally
        return !!landingPage.noIndex;
    }

    if (story?.content?.component && story?.content?.component === 'VariableLandingPage') {
        return true;
    }

    return false;
};

async function fetchStories(
    components: string[],
    version: 'draft' | 'published',
    cv: number,
    currentPage: number,
    allStories: StoryblokStoryInterface[] = [],
): Promise<StoryblokStoryInterface[]> {
    if (!TOKEN) {
        throw new Error('STORYBLOK_ACCESS_TOKEN not set!');
    }
    let response;
    try {
        console.log(`[REQUEST]: Storyblok stories API page ${currentPage}`);
        response = await Storyblok().get('cdn/stories', {
            version,
            token: TOKEN,
            filter_query: {
                component: {
                    in: components.join(','),
                },
            },
            cv,
            per_page: PER_PAGE,
            page: currentPage,
            resolve_links: 'url',
        });
    } catch (error) {
        console.error('Failed to fetch data', error);
        return allStories;
    }

    const newStories = response.data.stories;
    if (!newStories.length) {
        return allStories;
    }

    return fetchStories(components, version, cv, currentPage + 1, allStories.concat(newStories));
}

const sanitizeUrlSlugFromStoryblok = (url: string) => url.replaceAll('/', '').replaceAll(' ', '');

export default async (components: string[], version: 'draft' | 'published' = 'published'): Promise<Route[]> => {
    console.log('[FETCHING ROUTES]:', components.join(', '));

    const cv = Math.floor(Date.now() / 1e3);
    const allStories = await fetchStories(components, version, cv, 1, []);

    const routes: Route[] = [];
    for (let i = 0; i < allStories.length; i += 1) {
        const story = allStories[i];
        const route = {
            lastmod: story.published_at,
            full_slug: story.full_slug,
            noIndex: determineIfStoryIsNoIndex(story),
        };
        if (story.content.component === 'VariableLandingPage') {
            const content = story.content as unknown as VariableLandingPageBlokInterface;
            const baseUrl = `/${story.full_slug}/`;
            content?.categories?.forEach((category) => {
                const categoryUrl = `${baseUrl}${sanitizeUrlSlugFromStoryblok(category.url)}/`;
                category?.variants?.forEach((variant) => {
                    const variantUrl = `${categoryUrl}${sanitizeUrlSlugFromStoryblok(variant.url)}`;
                    console.log(`[ROUTE FOUND]: ${variantUrl}`);
                    routes.push({
                        ...route,
                        url: variantUrl,
                    });
                });
            });
        } else {
            const url = `/${story.full_slug}`;
            console.log(`[ROUTE FOUND]: ${url}`);
            routes.push({
                ...route,
                url,
            });
        }
    }

    console.log('[TOTAL REQUESTS]:', Math.ceil(allStories.length / PER_PAGE));
    console.log('[TOTAL ROUTES]:', routes.length);
    routes.forEach((route) => {
        if (!route.url.endsWith('/')) {
            route.url += '/';
        }
    });
    return routes;
};
