import type { MetaInfo, MetaPropertyName, MetaPropertyProperty } from 'vue-meta';
import type { Head } from '@unhead/schema';
import type { WithContext, WebSite, WebPage, Person, Organization } from 'schema-dts';
import type { ISbComponentType } from 'storyblok-js-client';
import { determineIfStoryIsNoIndex } from '~/api/storyblok/routes';

const COMPANY_NAME = 'EnergySage';

function splitList(items: string): string[] | undefined {
    if (items) {
        return items.split(',').map((s) => s.trim());
    }
    return undefined;
}

export interface MetaInfoPageSpecificBackupsInterface {
    title?: string;
    description?: string;
    image?: string;
}

export function generateHeadInfo(story: StoryblokStoryInterface, path: string): Head {
    const baseUrl = `https://www.energysage.com`;
    let href = `${baseUrl}${path}`;
    if (story?.content?.component === 'LandingPage' || story?.content?.component === 'VariableLandingPage') {
        // possible to override canonicalUrl
        const landingStory = story.content as unknown as LandingPageBlokInterface;
        const canonicalUrl = landingStory.canonicalUrl;

        if (canonicalUrl) {
            href = canonicalUrl.story?.full_slug
                ? `${baseUrl}/${canonicalUrl.story.full_slug}`
                : canonicalUrl.url || href;
        }
    }

    return {
        link: [
            {
                rel: 'canonical',
                href,
            },
        ],
    };
}

export function generateMetaInfo(
    story: StoryblokStoryInterface,
    path: string,
    domain: string,
    pageSpecificBackups?: MetaInfoPageSpecificBackupsInterface,
): MetaInfo {
    const {
        title,
        description,
        og_title,
        og_description,
        og_image,
        twitter_title,
        twitter_description,
        twitter_image,
    } = story?.content?.seo || {
        title: '',
        description: '',
        og_title: '',
        og_description: '',
        og_image: '',
        twitter_title: '',
        twitter_description: '',
        twitter_image: '',
    };

    // if backup info specific to the page type is provided, define it here so it can be used
    const backupTitle = pageSpecificBackups?.title || '';
    const backupDescription = pageSpecificBackups?.description || '';
    const backupImage = pageSpecificBackups?.image || '';

    const meta: (MetaPropertyName | MetaPropertyProperty)[] = [
        { name: 'description', content: description || backupDescription },
        // Open Graph tags
        { property: 'og:site_name', content: COMPANY_NAME },
        { property: 'og:url', content: `${domain}${path}` },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: og_description || description || backupDescription },
        { property: 'og:title', content: og_title || title || backupTitle },
        { property: 'og:image', content: og_image || backupImage },
        // Twitter tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: twitter_title || title || backupTitle },
        { name: 'twitter:description', content: twitter_description || description || backupDescription },
        { name: 'twitter:image', content: twitter_image || backupImage },
    ];
    if (determineIfStoryIsNoIndex(story)) {
        meta.push({ name: 'robots', content: 'noindex', hid: 'robots-meta-tag' });
    }

    return {
        title: title || backupTitle,
        meta,
    };
}

function getPublisher(seoData: SeoDataInterface, domain: string): Organization {
    const {
        supportEmail,
        supportPhoneNumber,
        companyEmail,
        companyLogo,
        companyPhoneNumber,
        sameAsURLs,
        knowsAboutTags,
        areaServed,
    } = seoData || {};

    const logoDimensions = getStoryblokImageDetails(companyLogo);

    return {
        '@type': 'Organization',
        name: COMPANY_NAME,
        url: domain,
        logo: {
            '@type': 'ImageObject',
            url: companyLogo,
            width: `${logoDimensions.width}px`,
            height: `${logoDimensions.height}px`,
        },
        sameAs: splitList(sameAsURLs),
        contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'Customer Service',
            telephone: supportPhoneNumber,
            email: supportEmail,
        },
        email: companyEmail,
        telephone: companyPhoneNumber,
        knowsAbout: splitList(knowsAboutTags),
        areaServed: splitList(areaServed),
        // Hard coded these won't change
        founder: 'Vikram Aggarwal',
        foundingDate: '2011-01-01',
    };
}

export function generateHomepageJsonLd(
    { seoData }: { seoData: SeoDataInterface },
    domain: string,
): WithContext<WebSite> {
    const { alternateName } = seoData || {};

    const publisher: Organization = getPublisher(seoData, domain);

    return {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: `${domain}/`,
        name: COMPANY_NAME,
        alternateName,
        publisher,
    };
}

function generatePersonJsonLd(
    storyContent: AuthorPageBlokInterface,
    path: string,
    domain: string,
): WithContext<Person> | undefined {
    if (!storyContent) {
        return undefined;
    }
    const authorInfo = storyContent.authorInfo?.[0] || {
        name: '',
        title: '',
        avatar: {
            filename: '',
        },
    };
    const authorConnect = storyContent.authorConnect?.[0] || {
        linkedinLink: {
            url: '',
        },
        twitterLink: {
            url: '',
        },
    };
    const authorSocials = [];
    if (authorConnect.linkedinLink.url) {
        authorSocials.push(authorConnect.linkedinLink.url);
    }
    if (authorConnect.twitterLink.url) {
        authorSocials.push(authorConnect.twitterLink.url);
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'Person',
        name: authorInfo.name,
        image: authorInfo.avatar.filename,
        url: `${domain}${path}`,
        description: storyContent.jsonLdDescription,
        jobTitle: authorInfo.title,
        publishingPrinciples: 'https://www.energysage.com/editorial-guidelines/',
        sameAs: authorSocials.length ? authorSocials : undefined,
        alumniOf: storyContent.authorEducationalOrganizationName
            ? {
                  '@type': 'EducationalOrganization',
                  name: storyContent.authorEducationalOrganizationName,
              }
            : undefined,
    };
}

export function generateLandingPageJsonLd(
    {
        story,
        seoData,
        path,
        pageSpecificBackups,
    }: {
        story: StoryblokStoryInterface;
        seoData: SeoDataInterface;
        path: string;
        pageSpecificBackups?: MetaInfoPageSpecificBackupsInterface;
    },
    domain: string,
): WithContext<WebPage> {
    const { title, description } = story?.content?.seo || {};

    const backupTitle = pageSpecificBackups?.title || '';
    const backupDescription = pageSpecificBackups?.description || '';

    const publisher: Organization = getPublisher(seoData, domain);

    const authorSchema: WithContext<Person>[] = [];
    let authors: StoryblokStoryInterface<AuthorPageBlokInterface>[] = [];

    if (story?.content?.component === 'LandingPage') {
        const landingPageStory = story as unknown as StoryblokStoryInterface<LandingPageBlokInterface>;
        const landingDataCardHero = landingPageStory?.content?.body?.find(
            (blok: ISbComponentType<string>) => blok.component === 'LandingDataCardHero',
        ) as unknown as LandingDataCardHeroBlokInterface | undefined;
        if (landingDataCardHero) {
            authors = landingDataCardHero.authors;
        }

        authors.forEach((author) => {
            const authorPath = author.full_slug ? `/${author.full_slug}/` : '';
            const authorJsonLd = generatePersonJsonLd(author.content, authorPath, domain);
            if (authorJsonLd) {
                authorSchema.push(authorJsonLd);
            }
        });
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        url: `${domain}${path}`,
        name: title || backupTitle,
        description: description || backupDescription,
        publisher,
        author: authorSchema,
    };
}

export function generateAuthorPageJsonLd(
    {
        storyContent,
        path,
    }: {
        storyContent: AuthorPageStoryInterface;
        path: string;
    },
    domain: string,
): WithContext<Person> {
    const authorInfo = storyContent.authorInfo?.[0] || {
        name: '',
        title: '',
        avatar: {
            filename: '',
        },
    };
    const authorConnect = storyContent.authorConnect?.[0] || {
        linkedinLink: {
            url: '',
        },
        twitterLink: {
            url: '',
        },
    };
    const authorAbout = storyContent.authorAbout?.[0] || {
        authorDescription: '',
    };

    const authorSocials = [];
    if (authorConnect.linkedinLink.url) {
        authorSocials.push(authorConnect.linkedinLink.url);
    }
    if (authorConnect.twitterLink.url) {
        authorSocials.push(authorConnect.twitterLink.url);
    }

    return {
        '@context': 'https://schema.org',
        '@id': `${domain}${path}`,
        '@type': 'Person',
        name: authorInfo.name,
        description: storyContent.seo?.description ? storyContent.seo?.description : authorAbout.authorDescription,
        jobTitle: authorInfo.title,
        image: authorInfo.avatar.filename,
        publishingPrinciples: 'https://www.energysage.com/editorial-guidelines/',
        sameAs: authorSocials.length ? authorSocials : undefined,
        alumniOf: storyContent.authorEducationalOrganizationName
            ? {
                  '@type': 'EducationalOrganization',
                  name: storyContent.authorEducationalOrganizationName,
              }
            : undefined,
    };
}
